<template>
	<div>
		<div v-if="statsLoading">
			<div class="flex">
				<div style="flex: 1">
					<div class="card p-2">
						<Skeleton width="100%" height="4rem"></Skeleton>
					</div>
				</div>
				<div style="flex: 1">
					<div class="card mx-2 p-2">
						<Skeleton width="100%" height="4rem"></Skeleton>
					</div>
				</div>
				<div style="flex: 1">
					<div class="card p-2">
						<Skeleton width="100%" height="4rem"></Skeleton>
					</div>
				</div>
				<div style="flex: 1">
					<div class="card p-2">
						<Skeleton width="100%" height="4rem"></Skeleton>
					</div>
				</div>
			</div>
		</div>
		<div class="grid" v-else>
			<div class="col-12 md:col-4" v-if="statsForToday">
				<div class="card mb-0">
					<div class="flex justify-content-between mb-3">
						<div>
							<span class="block text-500 font-medium mb-3">Agencies</span>
							<div class="text-900 font-medium text-xl">{{statsForToday.agencies}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
							<i class="pi pi-building text-blue-500 text-xl"></i>
						</div>
					</div>
					<span class="text-500">agencies registered in total</span>
				</div>
			</div>
			<div class="col-12 md:col-4" v-if="statsForToday">
				<div class="card mb-0">
					<div class="flex justify-content-between mb-3">
						<div>
							<span class="block text-500 font-medium mb-3">Tours</span>
							<div class="text-900 font-medium text-xl">{{statsForToday.tours}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
							<i class="pi pi-map text-orange-500 text-xl"></i>
						</div>
					</div>
					<span class="text-500">tours created in total</span>
				</div>
			</div>
			<div class="col-12 md:col-4" v-if="statsForToday">
				<div class="card mb-0">
					<div class="flex justify-content-between mb-3">
						<div>
							<span class="block text-500 font-medium mb-3">Pending Tours</span>
							<div class="text-900 font-medium text-xl">{{statsForToday.pendingTours}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-teal-100 border-round" style="width:2.5rem;height:2.5rem">
							<i class="pi pi-exclamation-triangle text-teal-500 text-xl"></i>
						</div>
					</div>
					<span class="text-500">tours created in total</span>
				</div>
			</div>
			<div class="col-12 md:col-6" v-if="statsForToday">
				<div class="card mb-0">
					<div class="flex justify-content-between mb-3">
						<div>
							<span class="block text-500 font-medium mb-3">User</span>
							<div class="text-900 font-medium text-xl">{{statsForToday.users}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
							<i class="pi pi-users text-cyan-500 text-xl"></i>
						</div>
					</div>
					<span class="text-500">users registered in total</span>
				</div>
			</div>
			<div class="col-12 md:col-6" v-if="statsForToday">
				<div class="card mb-0">
					<div class="flex justify-content-between mb-3">
						<div>
							<span class="block text-500 font-medium mb-3">Bookings</span>
							<div class="text-900 font-medium text-xl">{{statsForToday.bookings}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-indigo-100 border-round" style="width:2.5rem;height:2.5rem">
							<i class="pi pi-book text-indigo-500 text-xl"></i>
						</div>
					</div>
					<span class="text-500">bookings done till now</span>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12" v-if="monthlyLoading">
				<div class="card p-2">
					<Skeleton width="100%" height="16rem"></Skeleton>
				</div>
			</div>
			<div v-else class="col-12">
				<div class="card" v-if="monthlyBookings">
					<h5>Monthly Bookings</h5>
					<Chart type="bar" :data="monthlyBookings" :options="lineOptions" />
				</div>
			</div>

			<div class="col-12 md:col-4" v-if="mostToursLoading">
				<div class="card p-2">
					<Skeleton width="100%" height="10rem"></Skeleton>
				</div>
			</div>
			<div v-else class="col-12 md:col-4">
				<div class="card" v-if="mostToursToPlaces">
					<h5>Most Tours To Places</h5>
					<Chart type="pie" :data="mostToursToPlaces" :options="lightOptions"/>
					<div class="flex justify-content-center flex-wrap mt-2">
						<div class="flex align-items-center justify-content-center" v-for="(item, index) of mostToursToPlaces.labels" :key="index" style="float: left">
							<Tag class="m-1" :style="{'background-color': mostToursToPlaces.datasets[0].backgroundColor[index]}">{{item}}</Tag>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 md:col-8" v-if="agencyLoading">
				<div class="card p-2">
					<Skeleton width="100%" height="10rem"></Skeleton>
				</div>
			</div>
			<div class="col-12 md:col-8">
				<div class="card" v-if="toursByAgencies">
					<div>
						<h5>Tours By Agencies</h5>
						<div><Chart type="bar" :data="toursByAgencies" :options="lineOptions"/></div>
						<!-- <div class="flex justify-content-center flex-wrap mt-2">
							<div class="flex align-items-center justify-content-center" v-for="(item, index) of toursByAgencies.labels" :key="index" style="float: left;">
								<Tag  class="m-1" :style="{'background-color': toursByAgencies.datasets[0].backgroundColor[index]}">{{item}}</Tag>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import Axios from 'axios';

export default {
	setup() {
		const statsForToday = ref();
		const mostToursToPlaces = ref();
		const monthlyBookings = ref();
		const toursByAgencies = ref();
		const statsLoading = ref(true);
		const monthlyLoading = ref(true);
		const mostToursLoading = ref(true);
		const agencyLoading = ref(true);
		
		const lightOptions = ref({
			plugins: {
				legend: {
					display: false,
					position: 'bottom',
                    labels: {
                        color: '#495057',
                    }
                }
            }
        });

		const lineOptions = ref({
			plugins: {
				legend: {
					labels: {
						color: '#495057'
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: '#495057'
					},
					grid: {
						color: '#ebedef'
					}
				},
				y: {
					ticks: {
						color: '#495057'
					},
					grid: {
						color: '#ebedef'
					}
				}
			}
		});

		const getStatsForToday = () =>{
			Axios
			.get("api/admin/dashboard/totals")
			.then((res) =>{
				if (res.data.response == "success") {
					statsLoading.value = false;
					statsForToday.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getStatsForToday();

		const getMonthlyBookings = () =>{
			Axios
			.get("api/admin/dashboard/monthly-tours")
			.then((res) =>{
				if (res.data.response == "success") {
					monthlyLoading.value = false;
					monthlyBookings.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getMonthlyBookings();
		
		const getMostToursToPlaces = () =>{
			Axios
			.get("api/admin/dashboard/most-tours-by-places")
			.then((res) =>{
				if (res.data.response == "success") {
					mostToursLoading.value = false;
					mostToursToPlaces.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getMostToursToPlaces();

		const getToursByAgencies = () =>{
			Axios
			.get("api/admin/dashboard/tours-by-agencies")
			.then((res) =>{
				if (res.data.response == "success") {
					agencyLoading.value = false;
					toursByAgencies.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getToursByAgencies();

		return {
			statsLoading,
			monthlyLoading,
			mostToursLoading,
			agencyLoading,
			statsForToday,
			mostToursToPlaces,
			monthlyBookings,
			toursByAgencies,
			lightOptions,
			lineOptions
		}
	}
}
</script>